.partners.container {
    padding-bottom: 100px;
}

.partners .modal-content-area {
	overflow-y: auto;
	background-color: #fafafa;
}

.partners .material-symbols-rounded {
	font-size: 32px;
}

.partners h1 {
    font-size: 50px;
    margin: 0.5em 0;
}

.partners h4 {
    opacity: 0.8;
    font-size: 18px;
}

.partners .order-row {
    border: 1px solid lightgrey;
    border-radius: 3px;
    margin-bottom: 10px;
    font-size: 13px;
    cursor: pointer;
    transition: 300ms;
}

.partners .order-row.has-details {
    border-color: var(--resaltado)
}

.partners .order-row.active {
    border-color: var(--gris-texto);
}

.partners .order-row.has-details:not(.active):hover {
    background-color: var(--gris-texto);
    border-color: lightgrey;
    color: white;
}

.partners .order-row > div {
    padding: 10px 20px;
    border-right: 1px solid #efefef;
}

.partners .order-row > div:first-child {
    border-radius: 3px 0 0 3px;
}

.partners .order-row > div:last-child {
    border-right: none;
    border-radius: 0 3px 3px 0;
}

.partners .order-row .delete-button {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    right: -12px;
    bottom: 0;
    padding: 11px 0 11px 8px;
    transition: 300ms;
}

.partners .order-row:hover .delete-button {
    opacity: 1;
    right: -20px;
    filter: none;
}

.partners .order-row.has-details:hover .delete-button {
    filter: invert(1);
}

.partners .order-row:hover .delete-button:hover {
    opacity: 0.5;
}

.partners .order-details {
    border: 1px solid lightgrey;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 10px 20px;
    position: relative;
    font-size: 13px;
    flex-direction: column;
}

.partners .order-details .modal-close-button.tock-icon {
    font-size: 19px;
    padding: 7px 5px;
    width: 32px;
    height: 32px;
}

.partners .order-row:not(.has-details) + .order-details .modal-close-button.tock-icon {
    margin-top: 4px;
}

.partners .order-details .add-order-extras-button {
    text-align: center;
    color: #929292;
    margin: 0 auto;
    cursor: pointer;
}

.partners .extras-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.partners .extras-row:last-child {
    margin-bottom: unset;
}

.partners .extras-row .name {
    margin-left: 1em;
}

.partners .extras-row .quantity {
    background: var(--resaltado);
    color: white;
    padding: 5px;
    font-size: 0.9em;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-left: 1em;
    cursor: pointer;
}

.partners .extras-row .quantity i {
    display: none;
}

.partners .extras-row .quantity:hover span {
    display: none;
}

.partners .extras-row .quantity:hover i {
    display: block;
}

.partners .extras-row .editting-quantity {
    background: var(--resaltado);
    color: white;
    padding: 5px;
    font-size: 0.9em;
    border-radius: 5px;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: bold;
    margin-left: 1em;
}

.partners .accounts-list {
    margin-left: auto;
    cursor: pointer;
    margin-right: 0.5em;
    padding-right: 0.5em;
    border-right: 1px solid lightgrey;
    width: 225px;
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.partners .accounts-list > span {
    display: inline-block;
}

.partners .accounts-list > span img {
    transform: scale(0.5);
}

.partners .accounts-list.active > span img {
    transform: rotate(180deg) scale(0.5);
}

.partners .accounts-list-options {
    position: absolute;
    z-index: 2;
    padding: 8px 0;
    background-color: white;
    width: 225px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 10%);
    margin-top: 15px;
    max-height: 356px;
    overflow-y: scroll;
}

.partners .accounts-list-item {
    padding: 8px 8px 8px 16px;
    cursor: pointer;
    font-size: 0.9em;
    position: relative;
}

.partners .accounts-list-item.has-orders::before {
    content: "•";
    display: block;
    position: absolute;
    color: var(--resaltado);
    left: 6px;
    font-size: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.partners .add-order-extras-modal {
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.partners .add-order-extras-modal .confirm-button {
    margin-top: 30px;
    margin-left: auto;
}

.partners .add-order-extras-modal .item-row {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 15px 15px;
    align-items: center;
}

.partners .add-order-extras-modal .row-quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.partners .add-order-extras-modal .row-quantity span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: white;
}

.partners .add-order-extras-modal .row-quantity span:hover {
    background: #f6f6f6;
}

.partners .add-order-extras-modal .row-quantity span img {
    width: 16px;
    height: 16px;
}

.partners .add-order-extras-modal .qty {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--resaltado);
    color: white;
    font-weight: bold;
    font-size: 0.9em;
    text-align: center;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 0 0.5em;
    min-width: 2em;
}

.partners .add-order-button,
.partners .add-availability-button {
    float: right;
}

.partners .add-availability-button {
    margin-bottom: 2em;
}

.partners .availability-row {
	border: 1px solid lightgrey;
	font-size: 13px;
	padding: 10px 20px;
	border-radius: 3px 0 0 3px;
	position: relative;
}

.partners .availability-row .icon-actions {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
}

.partners .availability-row .material-symbols-rounded {
	font-size: 24px;
}

.partners .availability-row .quantity-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: white;
}

.partners .availability-row .quantity-button:hover {
    background-color: #f6f6f6;
}

.partners .availability-row .quantity-button img {
    width: 16px;
    height: 16px;
}

.partners .quantity-selector {
    display: flex;
    user-select: none;
}

.partners .quantity-selector .qty {
    border: 1px solid #f6f6f6;
    text-align: center;
    width: 3em;
}

.partners .add-availability-modal.container {
	padding: 0;
}

.partners .add-order-modal .boton-guardar,
.partners .add-availability-modal .boton-guardar {
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 25px;
    width: 250px;
}

.partners .add-order-modal .col,
.partners .add-availability-modal .col {
    display: flex;
    align-items: center;
}

.partners .add-order-modal input[type=number],
.partners .add-availability-modal input[type=number] {
    width: 4em;
    text-align: right;
}

.partners .add-order-modal .btn,
.partners .add-availability-modal .btn {
    margin-left: auto;
}

.partners .add-order-modal .form-input {
    margin-top: 1em;
}

.partners .add-availability-modal .custom-field,
.partners .add-availability-modal .custom-field input {
	margin: 0;
}

@media(max-width: 768px) {
    .partners h1 {
        font-size: 40px;
        margin-bottom: 1em;
    }

    .partners .account-list-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 18px;
        z-index: 3;
        width: 100%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-top: 1px solid lightgrey;
    }

    .partners .accounts-list {
        border-right: none;
        padding-right: 0;
        margin: 0;
        width: 100%;
    }

    .partners .accounts-list-options {
        width: 100%;
        box-shadow: 0px 0px 9px rgb(0 0 0 / 10%);
        overflow-y: scroll;
        bottom: 60px;
        padding-left: 0.75em;
    }

    .partners .accounts-list>span img {
        transform: scale(0.5) rotate(180deg);
    }
    
    .partners .accounts-list.active>span img {
        transform: scale(0.5);
    }

    .partners .availability-row {
        font-size: 13px;
    }

    .partners .tab-menu .content-area {
        width: 100%;
    }

    .partners .add-order-button {
        margin-bottom: 1em;
    }

    .partners .order-row {
        position: relative;
        flex-direction: column;
    }

    .partners .order-row>div {
        border-right: none;
        border-bottom: 1px solid #efefef;
    }

    .partners .order-row .delete-button {
        position: absolute;
        opacity: 1;
        bottom: unset;
        right: -10px;
        top: -9px;
        border: 1px solid lightgrey;
        border-radius: 18px;
        width: 36px;
        height: 36px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    }
    
    .partners .order-row.has-details:hover .delete-button,
    .partners .order-row:hover .delete-button {
        filter: none;
        right: -10px;
    }

    .partners .order-row:hover .delete-button:hover {
        opacity: 1;
    }

    .partners .order-row.has-details:not(.active):hover {
        background-color: inherit;
        border-color: inherit;
        color: inherit;
    }

    .partners .order-details {
        padding: 1em 0;
    }

    .partners .calendar {
        margin: 25px -15px;
    }
}
