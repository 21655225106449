body.scrolled .admin .edit-page.editar-evento {
	padding-top: 83px;
}

.admin .edit-page.editar-evento .descripcion {
	height: 300px;
}

.admin .edit-page.editar-evento .precio {
	margin-left: 0.35em;
}

.admin .edit-page.editar-evento .duracion {
	border: 1px solid #d7e0e2;
	background: white;
	margin: 0 0 0 0.35em;
	height: 36px;
    margin-top: -2px;
}

.admin .edit-page.editar-evento .toggles {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 20px 0;
}

.admin .edit-page.editar-evento .toggles > div {
	display: flex;
	align-items: center;
}

.admin .edit-page.editar-evento .toggles .form-toggle {
	margin-left: 0.35em;
}

.admin .edit-page.editar-negocio .json-editor .plus-wide,
.admin .edit-page.editar-evento .accordion-tab-container {
	margin-left: 0;
	margin-right: 0;
}