.custom-field input[type="email"],
.custom-field input[type="text"],
.custom-field input[type="number"],
.custom-field input[type="password"] {
	border: 1px solid #d7e0e2;
	margin-bottom: 10px;
	padding: 5px 10px;
	width: 100%;
}

.custom-field .number-field-container input[type="text"] {
	text-align: center;
	width: 50px;
	margin-bottom: 0;
}

.custom-field.type-text > div {
	height: 100%;
}

.custom-field textarea {
	width: 100%;
	height: 100%;
	border: 1px solid #d7e0e2;
	border-radius: 3px;
	padding: 5px 10px;
	min-height: 150px;
	resize: none;
}

.custom-field {
	margin-top: 0.5rem;
	position: relative;
}

.custom-field.type-currency {
	display: inline-block;
}

.custom-field .currency-symbol {
	position: absolute;
	right: 0.5em;
	top: calc(50% - 0.3em);
	transform: translateY(-50%);
}

.custom-field .currency-symbol.placement-before {
	left: 0.5em;
	right: unset;
}

.custom-field input.currency {
	text-align: right;
}

.custom-field input.currency.symbol-before {
	text-align: left;
}

.custom-field label span {
	font-weight: 400;
	opacity: 0.8;
	font-size: 0.9em;
}

.custom-field .number-field-container {
	display: flex;
	align-items: center;
}

.custom-field .number-field-container .plus,
.custom-field .number-field-container .minus {
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	cursor: pointer;
	margin-left: 0.25em;
}

.custom-field .number-field-container .plus:hover,
.custom-field .number-field-container .minus:hover {
	background-color: #fafafa;
}

.custom-field .number-field-container .plus img,
.custom-field .number-field-container .minus img {
	width: 18px;
	height: 18px;
}

.custom-field .number-field-container input[type="text"],
.custom-field .number-field-container input[type="text"]:focus {
	border: none;
	outline: none;
}
