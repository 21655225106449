.admin .editar-negocio {
	background: #fafafa;
}

.admin .editar-negocio .desplegable-tipos {
	height: 36px;
    margin-left: 1em;
    margin-top: -3px;
}

.admin .editar-negocio .editor-descripcion {
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid lightgrey;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents {
	display: flex;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .list-col:first-child {
	flex: 1;
	margin-right: 45px;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .list-col:nth-child(2) {
	flex: unset;
	margin-left: auto;
	justify-content: center;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .list-col:nth-child(2) label {
	display: none;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .icon-selector {
	justify-content: center;
	align-items: flex-end;
	padding-right: 10px;
	flex: 1;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .icon-selector img,
.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .icon-selector .placeholder {
	width: 64px;
	height: 64px;
}

.admin .editar-negocio .accordion-tab.detalles .accordion-tab .contents .list-col:first-child {
	float: right;
	margin-top: 16px;
	margin-left: 32px;
}

.admin .editar-negocio .accordion-tab.detalles .accordion-tab .contents .list-col:first-child label {
	display: none;
}

.admin .editar-negocio .accordion-tab.detalles .accordion-tab .contents .list-col:first-child img,
.admin .editar-negocio .accordion-tab.detalles .accordion-tab .contents .list-col:first-child .icon-selector .placeholder {
	width: 64px;
	height: 64px;
}

.admin .edit-page .header .icon-tooltip {
	text-transform: none;
}

.admin .edit-page .header .delete-negocio-container .material-symbols-rounded {
	font-size: 32px;
	margin-left: 5px;
	margin-top: 5px;
}

.admin .editar-negocio .usuario-administrador {
	margin-bottom: 0.5em;
}

.admin .editar-negocio .eventos-grid {
	display: grid;
	grid-template-columns: repeat(4, 4fr);
	gap: 25px;
}

@media (min-width: 1500px) {
	.admin .editar-negocio .eventos-grid {
		grid-template-columns: repeat(5, 5fr);
	}
}

@media (max-width: 1200px) {
	.admin .editar-negocio .eventos-grid {
		grid-template-columns: repeat(2, 2fr);
	}
}

.admin .editar-negocio .eventos .evento {
	display: inline-flex;
	flex-direction: column;
	margin-right: 25px;
	margin-bottom: 25px;
	width: 100%;
	height: 250px;
	cursor: pointer;
	border: 1px solid lightgrey;
	border-radius: 3px;
	padding-top: 0;
	box-sizing: border-box;
}

.admin .editar-negocio .eventos .evento.nuevo-evento {
	justify-content: center;
	align-items: center;
	background-color: white;
}

.admin .editar-negocio .eventos .evento:hover {
	border: 1px solid #292929;
}

.admin .editar-negocio .evento .image-container {
	width: 100%;
	height: 200px;
	background-position: center;
	background-size: cover;
	border-radius: 3px 3px 0 0;
	margin-bottom: auto;
}

.admin .editar-negocio .evento .details {
	flex: 1;
	padding: 15px;
	font-size: 13px;
}

.admin .editar-negocio .evento .nombre {
	font-weight: bold;
	font-size: 15px;
}

.admin .edit-page.editar-negocio .json-editor .plus-wide,
.admin .edit-page.editar-negocio .accordion-tab-container {
	margin-left: 0;
	margin-right: 0;
}

.admin .edit-page.editar-negocio .chart-container {
	height: 100%;
}

.admin .edit-page.editar-negocio .chart-container > div {
	height: calc(100% - 35px);
}

.editar-negocio .estadisticas .columna > div {
    height: 100%;
}