.admin-negocios .event-carousel .item-container.placeholder {
	height: 220px;
	background-color: #eee;
}

.admin-negocios .evento-grid {
	display: grid;
	grid-template-columns: repeat(5, 5fr);
	gap: 25px;
}

.admin-negocios .evento-grid .carrusel-main-evento {
	height: 320px;
	box-sizing: border-box;
}

.admin-negocios .carrusel-main-evento.add-new {
	display: flex;
	align-items: center;
	justify-content: center;
}

.admin-negocios .row-actions {
	display: flex;
	align-items: center;
	margin: 0 -20px 20px -20px;
	padding: 5px 20px;
	border-bottom: 1px solid lightgrey;
}

.admin-negocios .row-actions .delete-container {
	margin-left: auto;
}

.admin-negocios .admin-bloque.filas .fila {
	padding-top: 0;
}

.admin-negocios .row-actions > div {
	position: relative;
}

.admin-negocios .row-actions .highlight-type .material-symbols-rounded,
.admin-negocios .row-actions .move-type-up .material-symbols-rounded,
.admin-negocios .row-actions .move-type-down .material-symbols-rounded {
	font-size: 30px;
	margin-right: 20px;
}

.admin-negocios .custom-field {
	margin-left: 20px;
	width: 250px;
}