.admin-page.admin-estadisticas .admin-bloque {
    border-top: none;
    padding-bottom: 0;
}

.admin-page.admin-estadisticas .admin-bloque.columnas .columna {
    min-height: calc(50vh - 93px);
}

.admin-page.admin-estadisticas .columna {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 10px;
    min-height: 32vh;
    height: 100%;
}

.admin-page.admin-estadisticas .columna h2 {
    text-align: left;
    align-self: flex-start;
    margin-bottom: 1.5em;
}

.estadisticas .chart-container,
.admin-page.admin-estadisticas .chart-container {
    display: flex;
    margin: 0 auto;
    flex: 1;
    justify-content: center;
}

.estadisticas .bar-chart.chart-container,
.admin-page.admin-estadisticas .bar-chart.chart-container {
    width: 100%;
}

.estadisticas .chart-container > div,
.admin-page.admin-estadisticas .chart-container > div {
    flex: 1;
}

.estadisticas .legend-container,
.admin-page.admin-estadisticas .legend-container {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 75px;
    font-size: 13px;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 25%;
}

.estadisticas .legend-container span,
.admin-page.admin-estadisticas .legend-container span {
    border-radius: 50%;
    margin: 5px 0;
    display: inline-flex;
    justify-content: flex-end;
}

.estadisticas .legend-container li,
.admin-page.admin-estadisticas .legend-container li {
    display: inline-block;
}

.estadisticas .tooltip,
.admin-page.admin-estadisticas .tooltip {
    font-size: 13px;
    background-color: var(--gris-texto);
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 5px 15px;
}

.estadisticas .tooltip span:nth-child(2),
.admin-page.admin-estadisticas .tooltip span:nth-child(2) {
    font-weight: normal;
    margin: 0 0.5em;
}

.estadisticas .country-list,
.admin-page.admin-estadisticas .country-list {
    width: 100%;
}

.estadisticas .chart-container.horizontal-bar-chart > div,
.admin-page.admin-estadisticas .chart-container.horizontal-bar-chart > div {
    height: 25px;
    width: 100%;
    position: relative;
}

.kpi-container {
    display: grid;
    grid-template-columns: 2fr 2fr;
    flex: 1;
    width: 100%;
    gap: 20px;
}

.kpi-container .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    color: var(--gris-texto);
    font-size: 16px;
    text-align: center;
    position: relative;
    user-select: none;
    min-height: 160px;
}


.kpi-container .caption {
    position: absolute;
    left: 15px;
    top: 15px;
    opacity: 0.75;
}

.kpi-container .value {
    font-size: 50px;
    font-weight: bold;
}
