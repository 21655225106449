.admin-page.admin-permisos .row-details > div:first-child {
    border-bottom: 1px solid lightgrey;
    margin: -20px -20px -20px -20px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4, 4fr);
}

.admin-page.admin-permisos .row-details > div:first-child > div {
    display: inline-flex;
    margin-right: 10%;
    padding: 15px 15% 15px 15px;
    border-right: 1px solid lightgrey;
}

.admin-page.admin-permisos .row-details > div:first-child .form-toggle {
    margin-left: auto;
}

.admin-page.admin-permisos .row-details > div:last-child {
    padding-top: 40px;
    display: flex;
    justify-content: flex-end;
}