.input-select {
	position: relative;
}

.input-select.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.input-select .placeholder {
	display: none;
}

.input-select.has-placeholder .placeholder {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 0.75em;
	border-right: 1px solid lightgrey;
	pointer-events: none;
	background-color: #fafafa;
}

.input-select .options-list .option.selected {
    background: black;
    color: white;
}

.input-select input {
	width: 100%;
	border: 1px solid lightgrey;
	padding: 5px 10px;
}

.input-select .options-list {
	position: absolute;
	top: unset;
	bottom: unset;
	background-color: white;
	z-index: 1;
	overflow-y: auto;
	max-height: 200px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-select.has-placeholder .options-list {
	top: 100%;
    right: 0;
}

.input-select .options-list > div {
	padding: 10px;
	font-size: 0.9em;
	cursor: pointer;
}

.input-select .options-list > div:hover {
	background-color: #292929;
	color: white;
}

.input-select.has-placeholder {
	display: flex;
	align-items: center;
	border: 1px solid lightgrey;
}

.input-select.has-placeholder input {
	border: none;
}

.input-select .combo-mode-button {
	height: 2em;
	border: 1px solid lightgrey;
	border-radius: 3px;
	width: 100%;
	cursor: pointer;
	min-width: 6em;
	display: inline-flex;
	align-items: center;
	padding: 5px 28px 5px;
}

.input-select.combo-mode {
	user-select: none;
	position: relative;
}

.input-select.combo-mode .material-symbols-rounded {
	position: absolute;
    right: 4px;
    top: 5px;
    font-size: 24px;
    color: black;
    display: inline-block;
    width: 24px;
    left: unset;
    transform: translate(0, 0);
}