.admin-faq .image-upload {
	height: 400px;
    background-size: cover;
}

.admin-faq .faq-header {
	display: flex;
	align-items: center;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin: 0 0 10px 0;
    text-align: center;
    font-size: 0.8em;
    cursor: pointer;
    color: #333;
    text-transform: uppercase;
    height: 56px;
    user-select: none;
    background-color: #fcfcfc;
    font-size: 13px;
	padding: 10px 10px 10px 10px;
	user-select: none;
	position: relative;
}

.admin-faq .faq-header:hover {
	background-color: #fafafa;
}

.admin-faq .faq-header.expanded {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-color: #292929 #292929 lightgrey #292929;
}

.admin-faq .faq-list.dragging .faq-header:hover::before {
	background-color: #292929;
	height: 1px;
	width: 100%;
	display: block;
	content: " ";
	position: absolute;
	top: -5px;
	left: 0;
	right: 0;
}

.admin-faq .faq-header.dragging {
	opacity: 0.5;
}

.admin-faq .faq-header > span {
	margin-right: auto;
	margin-left: 10px;
}

.admin-faq .faq-header .expand-button {
	display: flex;
    align-items: center;
    justify-content: center;
	height: 100%;
    border-left: 1px solid lightgrey;
    padding-left: 10px;
}

.admin-faq .faq-header .drag-button {
	width: 0;
	height: 24px;
	display: flex;
    align-items: center;
    justify-content: center;
	overflow: hidden;
	transition: 150ms;
	opacity: 0.5;
}

.admin-faq .faq-header:hover .drag-button {
	width: 24px;
	margin-right: -5px;
	cursor: grab;
}

.admin-faq .faq-content {
	padding: 15px 15px 85px 15px;
	border: 1px solid #292929;
	border-top-width: 0;
	margin-top: -10px;
	margin-bottom: 15px;
	border-radius: 0 0 5px 5px;
}

.admin-faq .faq-content label {
	font-weight: bold;
}

.admin-faq .faq-header.add-new {
	background-color: white;
	justify-content: center;
}


.admin-faq .faq-header.add-new:hover {
	background-color: #fafafa;
}

.admin-faq .faq-header.add-new > span {
	margin: 0;
}

.admin-faq .delete-button {
	float: right;
    margin-top: 10px;
}