.admin .custom-field.logo {
	width: 250px;
}

.admin .modal-editar-boton {
	padding: 15px;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.admin .modal-editar-boton > .boton-guardar {
	margin-top: 15px;
	margin-left: auto;
}