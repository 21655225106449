.video-upload video {
	max-width: 100%;
	height: 100%;
}

.video-upload .upload-controls {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: black;
	border-radius: 5px;
	padding: 5px 5px 0;
}

.video-upload .add-icon {
	display: none;
	color: white;
}

.video-upload:hover .upload-controls {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.video-upload:hover .add-icon {
	display: block;
}
