.admin-page.admin-usuarios .header .btn {
	width: 154px;
}

.admin-page.admin-usuarios .table-component {
	margin: 40px 20px;
}

.admin-page.admin-usuarios .row-details > div {
	display: grid;
	grid-template-columns: 2fr 2fr;
	gap: 15px;
}

.admin-page.admin-usuarios .row-details > .row-guardar {
	display: flex;
	justify-content: flex-start;
	font-weight: bold;
	border-top: 1px solid lightgrey;
	margin: 20px -20px -20px -20px;
	padding: 20px;
}

.admin-page.admin-usuarios .row-details > div:last-child {
	border-top: 1px solid lightgrey;
	margin: 20px -20px -20px -20px;
	padding: 20px;
}

.admin-page.admin-usuarios .row-details > .row-guardar > div {
	display: flex;
	align-items: center;
}

.admin-page.admin-usuarios .row-details > .row-guardar > div .form-toggle {
	margin-left: 1em;
}

.admin-page.admin-usuarios .row-details .custom-field,
.admin-page.admin-usuarios .row-details .custom-field,
.admin-page.admin-usuarios .row-details .custom-field,
.admin-page.admin-usuarios .row-details .custom-field {
	margin-top: 0;
}

.admin-page.admin-usuarios .row-details .custom-field input[type=email],
.admin-page.admin-usuarios .row-details .custom-field input[type=text],
.admin-page.admin-usuarios .row-details .custom-field input[type=number],
.admin-page.admin-usuarios .row-details .custom-field input[type=password] {
	margin-bottom: 0;
}

.admin-page.admin-usuarios .row-details .boton-guardar {
	margin-left: auto;
}

.admin-page.admin-usuarios .row-details > div.row-estadisticas {
	display: block;
}

.admin-page.admin-usuarios .row-details > div.row-estadisticas .columna {
	min-height: 420px;
}

.admin-page.admin-usuarios .chart-container {
	height: 340px;
	align-items: center;
	width: 50%;
}

.admin-page.admin-usuarios .chart-container > div {
	height: 100%;
}