.calendar-modal .modal-content-area {
    overflow-y: auto;
    min-height: 375px;
}

.calendar {
    display: flex;
    margin: 25px;
    position: relative;
}

.month {
    flex: 1;
    padding: 0 0 1em 0;
    margin-right: 35px;
    background: transparent;
    position: relative;
    overflow: hidden;
    user-select: none;
}

.month:last-child {
    margin-right: 0;
}

.month > div {
    display: grid;
    grid-template-columns: repeat(7, 7fr);
}

.month h3 {
    text-align: center;
    margin: -20px -20px 30px -20px;
    padding: 20px 0;
    background: red;
    color: #fff;
}

.month h4 {
    color: rgb(79, 79, 101);
    text-align: center;
    font-family: Circular;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1em;
    margin-top: 0.75em;
}

.dow {
    color: rgb(79, 79, 101);
    font-family: Circular;
    font-size: 16px;
    font-weight: 400;
}

.day {
    -webkit-box-align: center;
    align-items: center;
    background: #e9e9eb;
    border: 1px solid #d3d3d8;
    border-right-color: transparent;
    color: #4f4f65;
    display: -webkit-box;
    display: inline-flex;
    -webkit-box-flex: 1;
    flex: 1 0 0%;
    height: auto;
    -webkit-box-pack: center;
    justify-content: center;
    opacity: 0.3;
    border-width: 1px;
    margin-bottom: -1px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.month > div > .day:last-child:not(.dow-0) {
    margin-right: -1px;
}

.day.dow-0,
.day.last-of-month,
.day:last-child {
    border-right-color: #d3d3d8;
}

.allow-past .day,
.day.future,
.day.today {
    opacity: 1;
    cursor: pointer;
    color: rgb(79, 79, 101);
}

.day,
.dummy-day {
    line-height: 40px;
    background: #fff;
    font-family: Circular;
    font-size: 16px;
    line-height: 24px;
}

.day.past,
.day.future-60,
.day.disabled {
    background: #d3d3d828;
    color: #4f4f6528;
    cursor: default;
    opacity: 1;
    cursor: not-allowed;
    border-color: #d3d3d828;
}

.month .day:not(.future-60) + .day.future-60 {
    border-left-color: #d3d3d8;
}

.dummy-day {
    color: transparent;
    opacity: 0;
}

.day.today {
    position: relative;
}

.day.today::after {
    background-color: var(--gris-texto);
    border-radius: 4px;
    bottom: 0.4rem;
    content: "";
    display: block;
    height: 4px;
    left: 50%;
    margin-left: -2px;
    overflow: hidden;
    position: absolute;
    width: 4px;
}

.day.active {
    color: white;
    background-color: var(--resaltado);
}

.day.active.secondary {
    background-color: var(--resaltado);
}

.day.today.active::after {
    background-color: white;
}

.calendar-container {
    position: relative;
}

.calendar-arrow {
    background-color: transparent;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    padding: 10px 5px;
    user-select: none;
    top: 5px;
    opacity: 0.75;
    width: 44px;
    height: 44px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
}

.calendar-arrow:hover {
    background-color: #f6f6f6;
}

.calendar-previous {
    left: 0;
}

.calendar-next {
    right: 0;
}

.calendar-next img {
    transform: rotate(-90deg) scale(0.5);
}

.calendar-previous img {
    transform: rotate(90deg) scale(0.5);
}

.calendar-year {
    text-align: center;
    margin-top: 15px;
    font-size: 0.9em;
}

.calendar:not(.allow-selection) .day.no-availability:not(.past) {
    pointer-events: none;
}

.calendar .day.no-availability:not(.past) {
    color: lightgrey;
}

@media (max-width: 991px) {
    .calendar {
        margin: 25px 25px 0;
    }

    .month h4 {
        font-size: 14px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .calendar-arrow {
        top: 13px;
    }

    .dow {
        font-size: 14px;
    }

    .day,
    .dummy-day {
        font-size: 14px;
    }

    body .modal-inner .month {
        height: 320px;
    }

    .calendar-arrow {
        top: 4px;
    }
}
