header {
	transition: 300ms;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

header .navbar .navbar-nav .nav-link {
	font-weight: 700;
	font-family: "Circular";
	color: var(--gris-texto);
	font-size: 0.8rem;
	text-transform: uppercase;
	margin: 1rem 6px;
	padding: 1.25rem 1.5rem;
}

header.admin .navbar .navbar-nav .nav-link {
	min-width: 154px;
}

header .navbar-nav.mr-auto .nav-item {
	margin-left: 15px;
}

header .navbar-nav.mr-auto .nav-item:first-of-type {
	margin-left: 30px;
}

.navbar {
	padding: 0;
	background: white;
}

.navbar .container {
	padding-left: 20px;
	padding-right: 20px;
}

header .navbar .navbar-nav .nav-item {
	position: relative;
}

.nav-btn {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	line-height: 1.5;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	padding: 1.25rem 1.5rem;
	max-height: 60px;
	border: 1px solid black;
	font-family: "Circular";
	font-size: 0.8rem;
}

header .nav-btn:hover,
header .navbar .navbar-nav .nav-link.nav-btn.btn-brown:hover {
	background-color: rgba(0, 0, 0, 0.02);
	color: var(--gris-texto);
}

body.scrolled header {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1020;
	background: var(--fondo);
	box-shadow: rgba(0, 0, 0, 0.098) 0px 0px 20px 5px;
}

nav .reservar-ahora {
	opacity: 0;
	cursor: pointer;
	transition: opacity 500ms;
}

li.user-account-button-container {
	height: 88px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 9px;
}

.admin li.user-account-button-container {
	margin-left: 19px;
}

.user-account-button {
	margin-right: calc(1em + 15px) !important;
	margin-left: 15px;
}

li.user-account-button-close {
	height: 88px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2em;
	cursor: pointer;
	margin-right: 30px;
	margin-left: 25px;
}

.user-account-button-close .tock-icon {
	font-size: 2rem;
}

header.simple {
	border-bottom: 1px solid #d3d3d8;
	height: 83px;
}

.navbar-brand img {
	margin-left: 1rem;
	width: 195px;
	height: 65px;
}

header.desktop .user-menu {
	position: absolute;
	right: 32px;
	top: 60px;
	padding-top: 20px;
	display: none;
	text-align: right;
	z-index: 1;
	padding-left: 1em;
	padding-right: 1em;
	padding-bottom: 1em;
}

header.desktop .user-account-button.hover + .user-menu,
header.desktop .user-menu:hover {
	display: flex;
}

header.desktop .navbar-container {
	flex: 1;
	position: relative;
	overflow-y: hidden;
	width: 50vw;
	height: 82px;
}

header.desktop .navbar-container .navbar-nav {
	position: absolute;
	top: -5px;
	right: 0;
	transition: 300ms;
}

header.desktop .navbar-container .navbar-nav.user-actions {
	top: -85px;
	background-color: white;
}

body.scrolled:not(.scroll-lock) header .navbar-nav.user-actions {
	background-color: var(--fondo);
}

header.desktop.bg-offwhite .navbar-container .navbar-nav.user-actions {
	background-color: transparent;
}

header.desktop .navbar-container.active .navbar-nav {
	top: 75px;
}

header.desktop .navbar-container.active .navbar-nav.user-actions {
	top: -5px;
}

@media (max-width: 1080px) {
	header .navbar .navbar-nav .nav-link {
		font-size: 0.8rem;
		margin: 1rem 6px;
		padding: 1rem;
	}
}

@media (min-width: 991px) {
	header .navbar .navbar-nav .nav-item:hover::after {
		content: "";
		position: absolute;
		width: 80%;
		height: 0;
		left: 10%;
		bottom: 1.25rem;
		border-bottom: 1px solid #000;
	}
}

@media (max-width: 992px) {
	header.simple {
		height: 53px;
	}
}
