.icon-svg-container {
	position: relative;
}

.icon-svg-container img {
	transition: 200ms;
	opacity: 1;
}

.icon-svg-container .hover-image {
	position: absolute;
	left: 50%;
	top: 50%;
	transition: 200ms;
	opacity: 0;
	transform: translate(-50%, -50%);
}

.icon-svg-container.hover img {
	opacity: 0;
}

.icon-svg-container.hover .hover-image {
	opacity: 1;
}

div > .icon-tooltip {
	display: block;
	position: fixed;
	background: var(--gris-texto);
	color: white;
	font-size: 13px;
	white-space: nowrap;
	padding: 5px 10px;
	border-radius: 5px;
	top: 26px;
	box-shadow: 0 0 15px rgb(0 0 0 / 25%);
	font-weight: 600;
	transition: none;
	z-index: 2;
	opacity: 0;
	width: 0;
	pointer-events: none;
}

div:hover > .icon-tooltip,
div.hover > .icon-tooltip {
	position: absolute;
	width: unset;
	transition: top 500ms, opacity 500ms;
	z-index: 1;
	opacity: 1;
	top: 36px;
	right: 0;
}
