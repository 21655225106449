.table-component {
    font-size: 13px;
}

.table-component .table-header {
    border: 0;
    margin: 10px;
    border: 0;
    padding-bottom: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-component .table-row {
    height: 39px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin: 0 10px 10px;
    display: flex;
    align-items: center;
}

.table-component .table-row.expanded {
    border-radius: 5px 5px 0 0;
}

.table-component .table-row:not(.expanded):hover {
    background-color: #fafafa;
}

.table-component .table-header > span {
    padding: 0 20px;
}

.table-component .table-row > span {
    height: 32px;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-right: 1px solid #efefef;
}

.table-component .table-row span.center {
    justify-content: center;
}

.table-component .table-row span.right {
    justify-content: flex-end;
}

.table-component .table-row > *:nth-last-child(2) {
    border-right: 0;
}

.table-component .table-row > *:last-child {
    margin-left: auto;
    border-left: 1px solid #efefef;
}

.table-component .expand-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    cursor: pointer;
    user-select: none;
}

.table-component .table-row.expanded {
	border-color: #292929 #292929 lightgrey #292929;
}

.table-component .row-details {
    padding: 20px;
    border: 1px solid lightgrey;
    border-color: lightgrey #292929 #292929 #292929;
    border-radius: 0 0 5px 5px;
    margin: -10px 10px 10px;
    border-top-width: 0;
}