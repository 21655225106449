.json-editor .list-col,
.json-editor .custom-field,
.json-editor .custom-field > div,
.json-editor textarea {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.json-editor .json-list-edit {
	display: grid;
	grid-template-columns: 2fr 2fr;
	gap: 15px;
	padding-top: 5px;
}

.json-editor .json-list-edit .plus-wide {
	margin-top: 0;
	margin-bottom: auto;
}

.json-editor .custom-field .image-upload {
	min-height: 100px;
}

.json-editor .accordion-tab-container {
	position: relative;
}

.json-editor .accordion-tab-container .right-side {
	position: absolute;
	right: 55px;
	justify-content: flex-end;
	pointer-events: none;
	max-height: 35px;
}

.json-editor .accordion-tab-container .right-side .minus {
	pointer-events: all;
}

.json-editor .json-list-edit .accordion-tab-container {
	padding-top: 0;
}

.json-editor .json-list-edit .list-item .accordion-tab-container {
	padding-top: 0;
}

.json-editor .json-list-edit .list-item:nth-child(odd) .accordion-tab-container,
.json-editor .json-list-edit .accordion-tab-container:nth-child(odd),
.json-editor .json-list-edit .plus-wide:nth-child(odd) {
	margin-right: 0;
}

.json-editor .json-list-edit .list-item:nth-child(even) .accordion-tab-container,
.json-editor .json-list-edit .accordion-tab-container:nth-child(even),
.json-editor .json-list-edit .plus-wide:nth-child(even) {
	margin-left: 0;
}

.json-editor .span-two-columns {
	grid-column-start: 1;
    grid-column-end: 3;
}

.json-editor .plus-wide {
	display: block;
	border: 1px solid lightgrey;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 10px 0;
	margin: 15px 50px 0;
	background-color: white;
}

.json-editor .plus-wide:hover {
	background: #fafafa;
	border-color: grey;
}

.json-editor .plus-wide.disabled,
.json-editor .plus-wide.disabled:hover {
	opacity: 0.5;
	cursor: default;
	background-color: #fafafa;
	border-color: lightgrey;
}

@media(max-width: 992px) {
	.json-editor .json-list-edit {
		display: flex;
		flex-direction: column;
	}
}
