.mi-cuenta {
    padding-bottom: 150px;
}

.mi-cuenta h1 {
    color: var(--gris-texto);
    font-weight: 700;
    font-family: Circular;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 75px;
    margin-bottom: 1em;
}

.mi-cuenta h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.mi-cuenta form {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 10px;
}

.mi-cuenta .change-password-form  {
    grid-template-columns: 1fr;
}

.mi-cuenta .change-password-form input {
    max-width: 280px;
    margin-right: auto;
}

.mi-cuenta .upper-tab-menu-container {
    max-width: 600px;
    margin-right: auto;
}

.mi-cuenta .form-input input:focus + label,
.mi-cuenta .form-input input.non-valid:focus + label,
.mi-cuenta .form-input input.non-empty + label,
.mi-cuenta .form-input textarea:focus + label,
.mi-cuenta .form-input textarea.non-valid:focus + label,
.mi-cuenta .form-input textarea.non-empty + label {
    top: 10px;
}

.mi-cuenta .item-list {
    font-size: 0.85em;
}

.mi-cuenta .item-list label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7em;
}

.mi-cuenta .on-submit-button,
.mi-cuenta .change-password-button {
    display: inline-block;
    max-width: 250px;
}

.mi-cuenta .order-grid {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(4, 4fr);
}

.mi-cuenta .order-grid a:not(.btn) {
    text-decoration: underline;
}

.mi-cuenta .order-item {
    position: relative;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 4px 8px 0px rgb(27 28 36 / 8%);
    background: white;
}

.mi-cuenta .order-item a {
    font-size: 12px;
    padding: 12px 7px;
    height: auto;
    width: calc(100% - 20px);
    margin: 10px auto;
    display: block;
}

.mi-cuenta .order-item .restaurant-name {
    padding-bottom: 0;
    text-transform: uppercase;
    font-size: 18px;
    padding: 10px;
    font-family: Circular;
    font-weight: 700;
}

.mi-cuenta .restaurant-image {
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px 5px 0 0;
    position: relative;
}

.mi-cuenta .restaurant-image .btn:not(.btn-brown):not(.btn-small).extras-button {
    display: block;
    max-width: 50%;
    background: white;
    position: absolute;
    bottom: 0;
    left: 10px;
    border-radius: 3px;
    border-color: lightgrey;
}

.mi-cuenta .order-item .info-line {
    padding: 0 10px;
    font-size: 13px;
}

.mi-cuenta .order-item.in-progress {
    padding-bottom: 1px;
}

.mi-cuenta .order-item.in-progress span,
.mi-cuenta .pending-order-placeholder > span {
    color: white;
    border-radius: 3%;
    background: var(--resaltado);
    position: absolute;
    top: 10px;
    left: 10px;
    width: 170px;
    height: 40px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Circular;
    font-size: 13px;
    font-weight: bold;
    z-index: 1;
}

.mi-cuenta .tab-menu-reservas .content-area {
    padding-top: 8px;
}

.mi-cuenta .pagination {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.mi-cuenta .pagination .page-button {
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
}

.mi-cuenta .pagination .page-button:hover {
	background: var(--resaltado-claro);
}

.mi-cuenta .pagination .page-button.current {
	background: var(--resaltado);
	color: white;
}

.mi-cuenta h3 {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    font-family: unset;
    margin-bottom: -3px;
}

.mi-cuenta .promo-code-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightgrey;
}

.mi-cuenta .promo-code-row:first-of-type {
    margin-top: 5px;
    padding-top: 15px;
    border-top: 1px solid lightgrey;
}

.mi-cuenta .promo-code-image-container {
    width: 75px;
    height: 75px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 1.5em;
}

.mi-cuenta .no-promos .promo-code-image-container {
    background-color: #f0f0f0;
}

.no-promos .placeholder-bar {
    margin-left: 0;
    margin-top: 5px;
}

.mi-cuenta .promo-code-title {
    font-weight: bold;
    font-size: 13px;
}

.mi-cuenta .promo-code-description,
.mi-cuenta .promo-code-code {
    font-size: 13px;
}

.mi-cuenta .error-message {
    color: red;
    font-size: 13px;
}

.mi-cuenta .info-message {
    font-size: 13px;
}

.mi-cuenta .restaurantes-favoritos {
    margin-bottom: 50px;
}

.mi-cuenta .extras-modal .modal-content-area {
    overflow: auto;
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 15px;
    padding: 15px;
}

.mi-cuenta .extras-modal .item {
    display: flex;
    margin: 15px;
}

.mi-cuenta .extras-modal .image {
    min-width: 64px;
    height: 64px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
    position: relative;
}

.mi-cuenta .extras-modal .name {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5em;
    color: #333;
}

.mi-cuenta .extras-modal .details {
    line-height: 1.2;
    font-size: 13px;
    color: var(--gris-texto);
}

.mi-cuenta .extras-modal .quantity {
    background-color: var(--resaltado);
    border-radius: 3px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    margin-right: 0.25em;
    position: absolute;
    top: -5px;
    right: -10px;
}

@media (max-width: 1300px) {
    .mi-cuenta .order-grid {
        gap: 10px;
        grid-template-columns: repeat(3, 3fr);
    }
}

@media (max-width: 991px) {
    .mi-cuenta .order-grid {
        gap: 10px;
        grid-template-columns: repeat(2, 2fr);
    }

    .mi-cuenta .upper-tab-menu-container {
        max-width: unset;
    }

    .mi-cuenta .change-password-form input {
        max-width: unset;
    }

    .mi-cuenta h1 {
        text-align: left;
    }
}

@media (max-width: 767px) {
    .mi-cuenta {
        padding-bottom: 50px;
    }

    .mi-cuenta .order-item.in-progress,
    .mi-cuenta .pending-order-placeholder {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    .mi-cuenta .tab-menu-reservas .content-area {
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        padding-bottom: 10px;
    }

    .mi-cuenta .tab-menu-reservas .content-area::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    
    .mi-cuenta .order-grid {
        display: flex;
    }

    .mi-cuenta .order-grid .order-item {
        width: 80vw;
    }

    .mi-cuenta form {
        grid-template-columns: 1fr;
    }

    .mi-cuenta .grid-restaurantes-container {
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }

    .mi-cuenta .grid-restaurantes-container::-webkit-scrollbar {
        display: none;
    }

    .mi-cuenta .grid-restaurantes {
        width: 600px;
        display: flex;
        padding: 0;
    }

    .mi-cuenta .grid-restaurantes .grid-item-container {
        width: 80vw;
    }

    .mi-cuenta .extras-modal .modal-content-area {
        display: flex;
        flex-direction: column;
    }
}
