.form-toggle {
    width: 32px;
    height: 17px;
    background: var(--resaltado-claro);
    border: 1px solid var(--resaltado-transparente);
    border-radius: 15px;
    position: relative;
    cursor: pointer;
}

.form-toggle.disabled {
    opacity: 0.5;
    cursor: default;
}

.form-toggle::after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    border-radius: 15px;
    background: white;
    content: " ";
    display: block;
    border: 1px solid var(--resaltado);
    box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1);
    transition: left 100ms;
}

.form-toggle.active::after {
    left: 50%;
    background: var(--resaltado);
    box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}