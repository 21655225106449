.registro h1 {
	color: var(--gris-texto);
	font-weight: 700;
	font-family: Circular;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-top: 75px;
	margin-bottom: 1em;
}

.registro .form-container {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.registro .btn.btn-brown {
	margin: 25px 0 10px;
	width: 100%;
}

.registro .btn.btn-brown + div {
	margin-bottom: 50px;
}

.registro .form-input input {
	border-radius: 0;
	background: white;
}

.registro .form-input label {
	top: 23px;
}

.registro .form-input input:focus + label,
.registro .form-input input.non-valid:focus + label,
.registro .form-input input:focus + label,
.registro .form-input input.non-valid:focus + label,
.registro .form-input input.non-empty + label,
.registro .form-input textarea:focus + label,
.registro .form-input textarea.non-valid:focus + label,
.registro .form-input textarea.non-empty + label {
	top: 14px;
}

.registro a {
	text-decoration: underline;
}

.registro .error-message {
	text-align: center;
	color: crimson;
	grid-column: span 2;
}

.registro-gracias .container {
	margin-top: 10vh;
	margin-bottom: 25vh;
}

@media(max-width: 991px) {
	.registro .btn.btn-brown + div {
		font-size: 14px;
	}

	.registro .btn.btn-brown {
		margin-bottom: 0;
	}

	.registro .form-input {
		grid-column: span 2 / auto;
	}
}